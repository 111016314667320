import { getDefaultStore } from "jotai";
import { useMutation } from "@tanstack/react-query";
import axios from "../../libs/axios/axios";
import { isAuthenticatedAtom } from "../../hooks/isAuthenticatedAtom";
import { authenticatedUserAtom } from "../../hooks/authenticatedUserAtom";
const store = getDefaultStore();

export default function useSignout() {
  return useMutation({
    mutationFn: async () => {
      return axios.post("/signout").then((res) => {
        store.set(isAuthenticatedAtom, false);
        store.set(authenticatedUserAtom, {});

        return res;
      });
    },
  });
}
